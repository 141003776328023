/* eslint-disable no-useless-escape */
import config from '@/config/env-constants';
import _ from 'lodash';


function isValidAssetCode(assetTypes, assetCode) {
	let isValid = false;

	if (config.assetCodeRegex.test(assetCode)) {
		return true;
	}

	let customAssetTypes = _.filter(assetTypes, o => {
		return o.assetTagging === config.assetTagging.CUSTOM;
	});

	if (!_.isEmpty(customAssetTypes)) {
		for (const assetType of customAssetTypes) {
			let regex = new RegExp(assetType.regex);
			if (regex.test(assetCode)) {
				isValid = true;
				break;
			}
		}
	}
	return isValid;
}


export const ValidationUtil = {

	isValidURL(str) {
		let regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
		return !(!regex.test(str));
	},

	// Only allows a-z, A-z, 0-9 and hyphen
	isAlphaNumeric(string) {
		let result = (/^([Ñña-zA-Z\-0-9])*$/.test(string));
		return result;
	},

	isAlphaWithWhiteSpace(string) {
		let result = (/^([Ñña-zA-Z\-.\s])*$/.test(string));
		return result;
	},

	isAlphaWithWhiteSpaceAndComma(string) {
		let result = (/^([Ñña-zA-Z\-,\s])*$/.test(string));
		return result;
	},

	isValidAssetCode,

	isValidAssetCodeDefault(assetCode) {
		return config.assetCodeRegex.test(assetCode);
	},

	hasInvalidAsset(assetTypesObj, assetCodeArr) {
		for (const assetCode of assetCodeArr) {
			if (!isValidAssetCode(assetTypesObj, assetCode)) {
				return true;
			}
		}
		return false;
	},

	getInvalidAssets(assetTypesObj, assetCodeArr) {
		let invalidAssetCodes = [];
		for (const assetCode of assetCodeArr) {
			if (!isValidAssetCode(assetTypesObj, assetCode)) {
				invalidAssetCodes.push(assetCode);
			}
		}
		return invalidAssetCodes;
	},

	hasExistingAsset(assetCodeArr, assetsObj) {
		for (const assetCode of assetCodeArr) {
			if (assetsObj[assetCode]) {
				return true;
			}
		}
		return false;
	},

	hasAssetWithInTransitStatus(dispatchAssets, assetsObj) {
		let hasInTransitAsset = false;
		for (const assetCode of dispatchAssets) {
			let asset = assetsObj[assetCode];
			if (asset && asset.status === 'In-Transit') {
				hasInTransitAsset = true;
				break;
			}
		}
		return hasInTransitAsset;
	},

	getAssetWithInTransitStatus(dispatchAssets, assetsObj) {
		let assetsInTransit = '';
		for (const assetCode of dispatchAssets) {
			let asset = assetsObj[assetCode];
			if (asset && asset.status === 'In-Transit') {
				assetsInTransit += asset.assetCode + ', ';
			}
		}
		assetsInTransit = assetsInTransit.substring(0, assetsInTransit.length - 2);
		return assetsInTransit;
	},

	isValidPlateNo(plateNo) {
		return config.plateNoRegex.test(plateNo);
	},

	objectHasField(fieldName, fieldValue, objToCompare) {
		if (objToCompare && !_.isEmpty(objToCompare)) {
			let lowerCaseNames = []

			_.forEach(objToCompare, objectItem => {
				if (objectItem[fieldName] && typeof objectItem[fieldName] === 'string') {
					lowerCaseNames.push(objectItem[fieldName].toLowerCase());
				}
			});

			let lowerCaseFieldValue = fieldValue && typeof fieldValue === 'string' ? fieldValue.toLowerCase() : '';
			return lowerCaseNames.includes(lowerCaseFieldValue);
		}
		return false;
	},

	arrayHasValue(optionArr, fieldName, valueToCompare) {
		let found = false;
		if (optionArr && optionArr.length > 0) {
			found = optionArr.some(function (el) {
				if (el.value === null) {
					return false;
				}
				return el.value[fieldName] === valueToCompare;
			});
		}
		return found;
	},

	arrayHasValueDirectField(optionArr, fieldName, valueToCompare) {
		let found = false;
		if (optionArr && optionArr.length > 0) {
			found = optionArr.some(function (el) {
				return el[fieldName] === valueToCompare;
			});
		}
		return found;
	},

	// return empty string for null param
	removeExcessWhiteSpace(stringValue) {
		let trimmedStr = '';
		if (stringValue) {
			trimmedStr = stringValue.trim().replace(/\s{2,}/g, ' ');
		}
		return trimmedStr;
	},

	arrayContains(array, letters) {
		let status = false;
		letters.forEach(letter => {
			array.forEach(item => {
				if (item.includes(letter)) status = letter;
			});
		});
		return status;
	}
}
